import React, { useEffect, useState } from "react"
import "./style.scss"
import useHasMounted from "../../../utils/useHasMounted"
import { useWindowWidth } from "../../../services/hooks"
import { Link, navigate } from "gatsby"
import WebpImage from "../../WebpImage"


const militaryThumbnails = [
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealAirForce.png",
    alt: "Seal air force",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealArmy.png",
    alt: "Seal Army",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealNavy.png",
    alt: "Seal Navy",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealMarineCorps.png",
    alt: "Seal Marine Corps",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealCoastGuard.png",
    alt: "Seal Coast Guard",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealSpaceForce.png",
    alt: "Seal Space Force",
  },
  {
    src: "Pages/MilitaryPage/MilitarySupport/sealNationalGuardBureau.png",
    alt: "Seal National Guard Bureau",
  },
]

const LifemartGlasses = () => {
  const isMounted = useHasMounted()
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()

  const handleClick = (e) => {
    e.preventDefault()
    navigate(e.target.href)
  }

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  return (
    <div className="lifemart-glasses">
      <div className="lifemart-glasses-border">
        <div className='image-side'>
          <WebpImage fileName="Placeholder/Hubble-Eyeglasses-Artemis-Butterscotch-full.png" alt="" />
        </div>
        <div className='info-side'>
          <div className='info-text'>
            <div className="info-discount">
              <span>25% OFF All Glasses</span>
            </div>
            <div className="info-title"><p>Our Other <b>Frame</b> of Mind</p></div>
            <p>When it comes to prescription glasses and sunglasses, we deliver. High-quality, handcrafted frames. On-trend styles. With the option to add blue light filtering to every pair.</p>
          </div>
          <div className='info-buttons'>
            <Link onClick={handleClick} to="/glasses" className="btn btn-primary cta-btn">Shop Eyeglasses</Link>
            <Link onClick={handleClick} to="/sunglasses" className="btn btn-primary cta-btn">Shop Sunglasses</Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default LifemartGlasses
