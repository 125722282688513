import React, { useState, useEffect } from "react"
import { useWindowWidth } from '../../../services/hooks'
import {hubbleClassic} from "../../../services/data";
import WebpImage from "../../WebpImage"

import "./style.scss"

const LifemartValuePropsSection = () => {
  const windowWidth = useWindowWidth()
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    setIsMobile(windowWidth < 600)
}, [windowWidth])

  return (
    <div className="lifemart-value-props grid-section">
      <div className="value-props-holder">
        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/01-Hubble-Contacts-Start-for-$1.png"
            alt=""
          />

          <p className="value-title">Start for $1</p>
          <p className="value-copy">
            Pay $1 for your first {hubbleClassic.trialPeriodPairs} pairs of our Classic lenses – or try Hydro by Hubble for 60% off your first order.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/02-Hubble-Contacts-No-Hassle-Policies.png"
            alt=""
          />

          <p className="value-title">{isMobile ? 'Free, ' : ''}No-Hassle Policies</p>
          <p className="value-copy">
            Not seeing the value? Just send it back.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/03-Hubble-Contacts-Cancel-Any-Time.png"
            alt="Cancel your subscription at any time"
          />

          <p className="value-title">Cancel at any Time</p>
          <p className="value-copy">
            Cancel your contact lens subscription at any time, for any reason.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/04-Hubble-Contacts-Eye-Health.png"
            alt=""
          />

          <p className="value-title">Eye Health & Safety {isMobile ? <><br/>Peace-of-Mind</> : ''}</p>
          <p className="value-copy">
            All Hubble lenses are daily-wear contacts – the healthiest and
            safest type of lens.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/05-Hubble-Contacts-Secure-Payment.png"
            alt=""
          />

          <p className="value-title">Secure Payment</p>
          <p className="value-copy">
            Safety first across all payment platforms.
          </p>
        </div>
      </div>
    </div>
  )
}

export default LifemartValuePropsSection
