import React from "react"
import WebpImage from "../../WebpImage"

import "./style.scss"

const LifemartPartnership = () => {
  return (
    <div className="lifemart-partnership">
      <div className="lifemart-partnership-container">
        <p>
          Plus, receive an <span>exclusive</span> 15% off every monthly subscription order!
        </p>
        <span>
          In Partnership with
        </span>
        <WebpImage fileName="Placeholder/LifeMart.png" alt="" />
      </div>
    </div>
  )
}

export default LifemartPartnership
